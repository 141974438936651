<template>
  <div class="custom-navbar d-none d-lg-block mb-3">
    <b-navbar type="dark" variant="dark">
      <b-container>
        <b-navbar-brand
          class="d-flex flex-column"
          :to="{ name: isShopping ? 'ReportsIndex' : 'HomeIndex' }"
        >
          <div style="font-size: 1.8vw">Happy Jungle</div>
          <div style="font-size: 1vw" class="text-muted">
            {{ this.$store.getters['user/currentUserMainStore'].store.name }}
          </div>
        </b-navbar-brand>
        <b-collapse
          id="nav-collapse"
          is-nav
          class="d-flex justify-content-between"
        >
          <b-navbar-nav>
            <b-nav-item
              v-if="isAllowedToSee"
              active-class="active bg-dark"
              exact
              :to="{ name: 'HomeIndex' }"
            >
              <span class="fas fa-user-clock mr-2" />Aluguéis
            </b-nav-item>
            <b-nav-item
              v-if="isAllowedToSee"
              active-class="active bg-dark"
              exact
              :to="{ name: 'CheckoutsIndex' }"
            >
              <span class="fas fa-cash-register mr-2" />Caixa
            </b-nav-item>
            <b-nav-item-dropdown v-if="isAdmin" right text="Cadastros">
              <b-dropdown-item
                :to="{ name: 'ToysIndex' }"
                active-class="active bg-dark"
              >
                Brinquedos
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'StoresIndex' }"
                active-class="active bg-dark"
              >
                Lojas
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'PeriodsIndex' }"
                active-class="active bg-dark"
              >
                Períodos
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'ProductsIndex' }"
                active-class="active bg-dark"
              >
                Produtos
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'UsersIndex' }"
                active-class="active bg-dark"
              >
                Usuários
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right text="Relatórios">
              <b-dropdown-group header="Aluguéis">
                <b-dropdown-item
                  :to="{ name: 'ReportRents' }"
                  active-class="active bg-dark"
                  exact
                >
                  Geral
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'ReportRentsPerToy' }"
                  active-class="active bg-dark"
                >
                  Por Brinquedo
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'ReportRentsPerUser' }"
                  active-class="active bg-dark"
                >
                  Por Usuário
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-group header="Vendas">
                  <b-dropdown-item
                    :to="{ name: 'ReportProductsPayment' }"
                    active-class="active bg-dark"
                  >
                    Produtos
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'ReportPaymentMethods' }"
                    active-class="active bg-dark"
                  >
                    Pagamentos
                  </b-dropdown-item>
                </b-dropdown-group>
                <b-dropdown-divider />
                <b-dropdown-group header="Outros">
                  <b-dropdown-item
                    :to="{ name: 'ReportCheckouts' }"
                    active-class="active bg-dark"
                  >
                    Caixas
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown-group>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template #button-content>
                <span class="fas fa-user mr-1" /> {{ currentUserName }}
              </template>
              <!-- <b-dropdown-item @click="$emit('selectStore')">
                <span class="fas fa-university mr-2" /> Alterar Loja
              </b-dropdown-item> -->
              <b-dropdown-item
                :to="{ name: 'ChangePassword' }"
                active-class="active bg-dark"
              >
                <span class="fas fa-key mr-2" /> Alterar Senha
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="logout">
                <span class="fas fa-sign-out-alt mr-2" />
                Sair
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters('user', ['currentUserName']),
    ...mapGetters('store', ['isAdmin', 'isShopping']),
    isAllowedToSee() {
      return !this.isShopping
    }
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>

<style lang="scss">
.custom-navbar {
  .dropdown-item {
    &.active,
    &:active {
      background-color: #343a3f;
    }
  }
}
</style>
